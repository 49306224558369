import React, { useState } from "react"
import css from "@emotion/css"

import { Button } from "../../components/button"
import { Input } from "../../components/input"
import { JobsLayout } from "../../components/jobs-layout"
import { isValidEmail } from "../../components/is-valid-email"
import { useViewport } from "../../hooks/use-viewport-width"

import "../../components/forms.sass"

const JobsPage = () => {
  const [email, setEmail] = useState("")
  const viewport = useViewport()
  return (
    <JobsLayout>
      <form
        name="jobs-subscription"
        method="post"
        action="/jobs/subscription-success"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        css={css`
          display: flex;
          ${!viewport.isNarrowPlus ? "flex-direction: column;" : ""}
        `}
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="jobs-subscription" />
        <div
          className={!viewport.isNarrowPlus ? "field" : ""}
          css={css`
            width: 100%;
          `}
        >
          <Input
            name="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            isMedium={true}
            placeholder="jon.snow@westeros.com"
          />
        </div>
        <Button
          disabled={!isValidEmail(email)}
          css={css`
            white-space: nowrap;
            ${viewport.isNarrowPlus ? "margin-left: 30px;" : ""}
          `}
        >
          Stay in touch
        </Button>
      </form>
      <p
        css={css`
          font-size: 18px;
          text-align: center;
        `}
      >
        We won't spam you. We promise :)
      </p>
    </JobsLayout>
  )
}

export default JobsPage
