import React from "react"
import css from "@emotion/css"

export const Input = ({ isMedium, ...rest }) => (
  <div
    className="control"
    css={css`
      width: 100%;
    `}
  >
    <input className={`input${isMedium ? " is-medium" : ""}`} {...rest} />
  </div>
)
